import { FC, useState } from 'react'
import {
  HighlightedText,
  Spacer,
  Typography,
} from '@prometeoapi/afrodita-react-component-lib'

import {
  PageCard,
  PageCardTitle,
  PageCardDescription,
  StatsContainerProps,
  StatsItemProps,
  useHomeQuery,
  useHomeQueryDataHelpers,
} from 'src/presentation'

import styles from './home-page-api-key.module.scss'

const StatsItem: FC<StatsItemProps> = ({ header, footer, children }) => {
  return (
    <div>
      <Typography variant={'body-1'} typographyColor={'gray-6'}>
        {header}
      </Typography>

      <Typography variant={'featured-1'}>{children}</Typography>

      <Typography variant={'body-2'} typographyColor={'gray-4'}>
        {footer}
      </Typography>
    </div>
  )
}

const StatsContainer: FC<StatsContainerProps> = ({ header, children }) => {
  return (
    <div>
      <Typography variant={'emphasized-1'}>{header}</Typography>

      <Spacer />

      <div className={styles['stats-items-container']}>{children}</div>
    </div>
  )
}

export const HomePageApiKey = () => {
  const { data } = useHomeQuery()
  const [copied, setCopied] = useState(false)

  const obfuscateApiKey = (apiKey: string) => {
    const visibleLength = 5
    const maskedLength = apiKey.length - visibleLength
    return '*'.repeat(maskedLength) + apiKey.slice(-visibleLength)
  }

  const copyToClipboard = async (apiKey: string): Promise<void> => {
    await navigator.clipboard.writeText(apiKey)
    setCopied(true)
  }

  const {
    getApiKeyCardLabel,
    getApiKeyCardDescription,
    getApiKeyCardTitle,
    getApiKeyCardStatsContainerTitle,
    getStatsItemHeader,
    getStatsItemFooter,
    getStatsItemBody,
  } = useHomeQueryDataHelpers()
  return (
    <>
      {data && (
        <PageCard>
          <PageCardTitle>{getApiKeyCardTitle(data)}</PageCardTitle>

          <PageCardDescription>
            {getApiKeyCardDescription(data)}
          </PageCardDescription>

          <Spacer />

          <div
            onClick={() => copyToClipboard(data.user.apiKey)}
            style={{ cursor: 'pointer' }}
          >
            <HighlightedText label={getApiKeyCardLabel(data)}>
              {obfuscateApiKey(data.user.apiKey)}
            </HighlightedText>
          </div>

          {copied && <Typography>API Key copied to clipboard</Typography>}

          <Spacer />

          <StatsContainer header={getApiKeyCardStatsContainerTitle(data)}>
            <StatsItem
              header={getStatsItemHeader('requests')}
              footer={getStatsItemFooter(data)('requests')}
            >
              {getStatsItemBody(data)('requests')}
            </StatsItem>

            <StatsItem
              header={getStatsItemHeader('connections')}
              footer={getStatsItemFooter(data)('connections')}
            >
              {getStatsItemBody(data)('connections')}
            </StatsItem>

            <StatsItem
              header={getStatsItemHeader('useTime')}
              footer={getStatsItemFooter(data)('useTime')}
            >
              {getStatsItemBody(data)('useTime')}
            </StatsItem>
          </StatsContainer>
        </PageCard>
      )}
    </>
  )
}
